/** 
 * Model ze-tpv
 */
class ZeTpvModel {

  isTpvEnabled: boolean = false;

  isTpvVisible: boolean = false;
  
  t2PhIndicator: boolean = false;

  isTokenVerificationApplicable: boolean = false;

  paymentStatus?: boolean;

  context: {tokenId: string; context: string; merchantCode: string; tpvTerminal: string; tpvOrder: string} = {
    tokenId: '',
    context: '',
    merchantCode: '',
    tpvOrder: '',
    tpvTerminal: ''
  };

}

export default ZeTpvModel;
