import {
  GetPriceModelProperties,
  OptionalCRMProperties
} from '@/flows/flows-views/get-price/get-price-model';
import {
  PaymentMedium,
  ProductCode, ProductId, ProductOptionConditionType, ZeProductBehaviour, ZeProductCardVisibility, ZeVersion
} from '@/utils/enums';
import {
  BrandingConfig
} from '@/utils/interfaces';

export const defaultCOFBBrandingConfig: BrandingConfig = {
  cookies: '0190a5c4-071c-7f47-a2f1-6007a93df31a',
  phoneNumbers: {
    contactCenter: '932 44 07 20',
  },
  logos: {
    primary: {
      src: require('../../../assets/images/cofb/logo-zurich.svg'),
      className: '',
      removeDivider: false,
    },
    secondary: {
      src: require('../../../assets/images/cofb/logo-cofb.svg'),
      className: '',
      removeDivider: true,
    },
  },
  links: {
    mainSite: 'https://www.cofbserveis.net/',
    cookiesPolicy: 'https://zurichempresas.es/politica-de-cookies',
    privacyPolicy: 'https://zurichempresas.es/politica-de-privacidad',
    hiringPolicy: 'https://zurichempresas.es/politica-de-contratacion',
    informativeNote: '',
  },
  coverageDefaultInitialDateOffset: 3,
  //Products that can exist in the app
  products: [
    {
      id: ProductId.Commerce,
      code: ProductCode.Commerce,
      options: {
        productCard: {
          partVisible: 'both',
        },
        isRecommended: {
          condition: [
            {
              conditionType: ProductOptionConditionType.GuideMe,
            },
          ],
        },
        showPrice: {
          condition: [
            {
              conditionType: ProductOptionConditionType.CompetenceManagement,
              activityCode: '0202450', //Activity Code
            },
          ],
        },
      },
    },
    {
      id: ProductId.Accidents,
      code: ProductCode.Accidents,
      options: {
        dependencies: {
          products: [ProductId.Commerce],
          behaviour: ZeProductBehaviour.TriggerCallMeBack
        },
        productCard: {
          partVisible: ZeProductCardVisibility.PriceWrapper
        },
        quoteOnline: false
      }
    },
  ],
  flow: {
    views: {
      productSelection:{
        viewVersion: ZeVersion.Default,
      },
      billingAndEmployees: {
        viewVersion: {
          version: ZeVersion.Alpha,
          enabledByProduct: [ProductId.RC, ProductId.RcPro],
        },
        sections: {
          formGenerator: {
            enabled: true,
            components: {
              billingField: {
                enabled: false
              },
              employeesField: {
                enabled: true
              }
            }
          }
        }
      },
      selectedProducts: {
        sections: {
          heroSection: {
            enabled: true,
          },
          selectedProductsCoverages: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce],
          },
          tempImgSection: {
            enabled: false,
          },
          modifyCapitals: {
            enabled: true,
          },
          additionalProducts: {
            enabled: true,
          },
          faqs: {
            enabled: true,
          },
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce]
          },
        },
      },
      emailAndPhone: {
        sections: {
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [ProductId.Commerce]
          },
          header: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
            components: {
              customHeader: {
                features: {
                  showReturnButton: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
                  }
                }
              }
            }
          }
        },
        features: {
          isPhoneEditable: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          showLoadingSpinner: {
            enabled: true
          },
          doCreateModificationsArray: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          doCRMRequest: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro]
          },
          waitForCompleteQueue: {
            enabled: false
          }
        }
      },
      coversSummary: {
        viewVersion: {
          version: ZeVersion.Beta,
          enabledByProduct: [ProductId.RC, ProductId.RcPro],
        },
        sections: {
          priceInfo: {
            enabled: true,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                  showProductDetailsButton: {
                    enabled: false,
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              },
              selectedCoverageCardsSection: {
                constants: {
                  forceShowPriceWrapper: false,
                  forceShowProductsTitle: false
                }
              }
            }
          },
          coveragesTable: {
            enabled: false,
          },
          productsCards: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce],
          },
          meetinglawyersPromotion: {
            enabled: false,
            enabledByProduct: [],
          },
          documentationCard: {
            enabled: true,
            enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
          },
        },
      },
      onlinePaymentUserData: {
        viewVersion: {
          version: ZeVersion.Alpha,
          enabledByProduct: [ProductId.RC, ProductId.RcPro, ProductId.Commerce],
        },
        sections: {
          personTypeSelection: {
            enabled: false,
          },
          userDataForm: {
            enabled: true,
          },
          budgetAlert: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro],
          },
          documentationRowSection: {
            enabled: true,
            enabledByProduct: [ProductId.RC, ProductId.RcPro],
          },
        },
        constants: {
          preventRequotePriceOnTransition: true
        }
      },
      finishedOnlinePayment: {
        sections: {
          emissionMainMessage: {
            enabled: true,
            version: ZeVersion.Alpha,
          },
          policyNumberCopy: {
            enabled: true,
            version: ZeVersion.Default,
          },
          hiredProducts: {
            enabled: false,
            version: ZeVersion.Default,
            components: {
              buttonsWrapperComponent: {
                features: {
                  showButtons: {
                    enabled: true,
                    enabledByProduct: [ProductId.Commerce, ProductId.RC, ProductId.RcPro],
                  },
                  showProductDetailsButton: {
                    enabled: false,
                  },
                  showNotaMediadorButton: {
                    enabled: false
                  },
                },
                constants : {
                  scrollPositionAdjusmentIndex: 110
                },
              }
            }
          },
          productPromotion: {
            enabled: false,
            version: ZeVersion.Default,
          },
          buttonsSection: {
            enabled: true,
            version: ZeVersion.Default,
          },
        },
        constants:{
          optionalCRMProperties:[
            OptionalCRMProperties.Holder
          ],
          generateUrlLocationParams: false
        }
      },
      businessActivitySelection:{
        viewVersion: {
          version: ZeVersion.Alpha,
        },
        sections: {
          cardsSelector: {
            enabled: true,
            version: ZeVersion.Default,
          }
        },
        constants: {
          createCartItemType: ProductId.Commerce,
          createCartAvailableItems: [
            {
              type: ProductId.Accidents,
              preselected: true
            }
          ]
        }
      },
      holderAndRiskResume:{
        constants:{
          valuesToPersistInModel: [
            GetPriceModelProperties.Name,
            GetPriceModelProperties.Email
          ],
        }
      },
    },
    paymentType: {
      telephone: {
        enabled: false,
      },
      online: {
        enabled: true,
        paymentChannel: {
          T1: PaymentMedium.Bank,
          T2: PaymentMedium.Bank,
          T2_ph: PaymentMedium.Bank,
          PI: PaymentMedium.Bank
        }
      },
    },
    settings: {
      callMeBack: {
        buttons: {
          hideButtonCallMe: true
        }
      }
    }
  },
};


export const prodCofbBrandingConfig: BrandingConfig = {
  ...defaultCOFBBrandingConfig,
  cookies: '0190a5c2-77a5-7971-80a8-ca158d2d86c6',
};
