import {
  InAppBrandingConfig
} from '@/utils/interfaces';
import {
  defaultZurichBrandingConfig,
  prodZurichBrandingConfig
} from './zurich';
import {
  defaultOrangeBrandingConfig,
} from './orange';
import {
  defaultPimecBrandingConfig,
  prodPimecBrandingConfig,
} from './pimec';
import {
  defaultAsegasaBrandingConfig
} from './asegasa';
import {
  defaultSegurosolBrandingConfig
} from './segurosol';
import {
  defaultEstancosBrandingConfig
} from './estancos';
import {
  defaultNaturalOpticsBrandingConfig
} from './naturaloptics';
import {
  defaultCOFBBrandingConfig,
  prodCofbBrandingConfig
} from './cofb';

/**
 * Declaration of available brandings and basic settings
 * - Naming of the branding
 * - Urls
 * - Cookies
 * - Products
 * - Links
 * - Phone numbers
 */
export const brandingConfig: InAppBrandingConfig = {
  'default': {
    zurich: defaultZurichBrandingConfig,
    orange: defaultOrangeBrandingConfig,
    pimec: defaultPimecBrandingConfig,
    asegasa: defaultAsegasaBrandingConfig,
    estancos: defaultEstancosBrandingConfig,
    naturaloptics: defaultNaturalOpticsBrandingConfig,
    cofb: defaultCOFBBrandingConfig,
    segurosol: defaultSegurosolBrandingConfig,
  },
  local: {
    zurich: {
      cookies: '',
    },
    orange: {
      cookies: ''
    },
    pimec: {
      cookies: ''
    },
    asegasa: {
      cookies: '',
      flow: {
        settings: {
          webComponentAuto: {
            url: 'https://wc-auto.dev.zurmsv.com',
            branding: 'zurich',
            language: 'es',
            partnerId: '03356cfc-5872-450b-8fa2-2666dbbfb38d',
            apiKey: 'sqG8lvq1P88h9nmEuZJqL47KpTsY9ZOi9dXctkiu',
            brokerCode: '0000077860',
          }
        }
      }
    },
    estancos: {
      cookies: ''
    },
    naturaloptics: {
      cookies: ''
    },
    cofb: {
      cookies: ''
    },
    segurosol: {
      cookies: ''
    },
  },
  'int': {
    asegasa: {
      flow: {
        settings: {
          webComponentAuto: {
            url: 'https://wc-auto.ist.zurmsv.com',
            branding: 'zurich',
            language: 'es',
            partnerId: '03356cfc-5872-450b-8fa2-2666dbbfb38d',
            apiKey: 'CHP6mHad1y3FXX258UekQa8kn46x7ABB5MGtZ6Co',
            brokerCode: '0000077860',
          }
        }
      }
    }
  },
  uat: {
    asegasa: {
      flow: {
        settings: {
          webComponentAuto: {
            url: 'https://wc-auto.uat-zurich.es',
            branding: 'zurich',
            language: 'es',
            partnerId: '2846ecc1-2cfb-4de6-baa7-ca49a06895a5',
            apiKey: 'OkF1h8fi598kpuGvPtKZQ4jJ5j1mSQ8z4jsUYpEA',
            brokerCode: '0000077860',
          }
        }
      }
    }
  },
  prod: {
    zurich: prodZurichBrandingConfig,
    pimec: prodPimecBrandingConfig,
    cofb: prodCofbBrandingConfig,
    segurosol: defaultSegurosolBrandingConfig
  }
};

//Temporary Case: only for showcasing!!!
if (brandingConfig.int?.asegasa?.flow?.settings?.webComponentAuto?.url) {
  brandingConfig.int.asegasa.flow.settings.webComponentAuto.url = 'https://wc-auto.ist.zurmsv.com';
}
