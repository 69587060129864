import {
  convertEnumToObject
} from '@/utils/methods/convertEnumToObject';

import {
  ConfigurableUnit
} from '@/utils/interfaces';

import zurichProdNavigationTransitions from './zurich';

import orangeProdNavigationTransitions from './orange';

import asegasaNavigationTransitions from './asegasa';

import segurosolProdNavigationTransitions from './segurosol';

// Import sumProdNavigationTransitions from './sum';

import brokerProdNavigationTransitions from './broker';

import estancosProdNavigationTransitions from './estancos';

import naturalOpticsProdNavigationTransitions from './naturaloptics';

import COFBProdNavigationTransitions from './cofb';

export enum Steps {
  Welcome = 0,
  DataCapture = 1,
  AskForAdvice = 2,
  BusinessActivity = 3,
  ProductSelection = 4,
  PostalAddress = 5,
  BillingAndEmployees = 6,
  BuildingDetails = 7,
  LocalOwnership = 8,
  BuildingType = 9,
  FloorEmplacement = 10,
  PartInsured = 11,
  Factors = 12,
  BusinessWindows = 13,
  SafetyMeasures = 14,
  HolderAndRisksResume = 15,
  EmailAndPhone = 16,
  PreparingPrice = 17,
  SelectedProducts = 18,
  CoversSummary = 19,
  PaymentMethod = 20,
  CallToComplete = 21,
  OnlinePaymentUserData = 22,
  FullPostallAddress = 23,
  FinishedOnlinePayment = 24,
  PropertyReform = 25,
  TelephonePaymentChoose = 26,
  TelephonePaymentClientCalls = 27,
  TelephonePaymentZurichCalls = 28,
  TelephonePaymentConfirmation = 29,
  ConfirmationOfPaymentData = 30,
  LocalMaxCapacity = 31,
  BankingData = 32,
  CardPayment = 40,
  PaymentProcessing = 33,
  Callmeback = 34,
  InternetExplorer = 35,
  PersonTypeSelection = 38,
  PolicyholderStatements = 39,
  WebComponentView = 41,
  //Components with transitions
  CallmebackComponent = 2000,
  //Full Forms
  AsegasaFullForm = 36,
  //App policies
  AppPolicies = 37,
  Layout = 2001
}

export interface CurrentStepControl {
  step: number;
  methods: {
    [key: string]: () => void;
  };
}

//T represents the enum of the steps of the current flow
export interface StepTransition<T> {
  name: keyof T;
  from: keyof T | (keyof T)[] | '*' | 'init';
  to: keyof T | 'previous';
  option?: number;
  isComponent?: boolean;
}

export interface StepMethods {
  [key: string]: {
    step: number;
    method: () => void;
  };
}

export const steps = convertEnumToObject(Steps);

export type InAppTransitions = ConfigurableUnit<StepTransition<typeof steps>[]>;

export const transitions: InAppTransitions = {
  'default': {
    zurich: zurichProdNavigationTransitions,
    orange: orangeProdNavigationTransitions,
    asegasa: asegasaNavigationTransitions,
    brokers: brokerProdNavigationTransitions, //Its the same flow as zurich for now
    segurosol: segurosolProdNavigationTransitions,
    estancos: estancosProdNavigationTransitions,
    naturaloptics: naturalOpticsProdNavigationTransitions,
    cofb: COFBProdNavigationTransitions
  },
  prod: {
    zurich: zurichProdNavigationTransitions,
    orange: orangeProdNavigationTransitions,
    brokers: brokerProdNavigationTransitions,
    estancos: estancosProdNavigationTransitions,
    naturaloptics: naturalOpticsProdNavigationTransitions,
    cofb: COFBProdNavigationTransitions
  }
};
