import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import 'vue-i18n';

import merge from 'lodash/merge';

import {
  EAVueApp,
  EAVueAppParameters,
  EAHookService,
  EAVueAppConfiguration,
  EABootstrapHooks,
  EAApplicationLogger,
  EAContextManager
} from '@zurich-es-npm/ea-front-web-core';

import {
  initialConfig,
  vApplication
} from './config/config';

import {
  EAApplicationSDKConfig
} from './config/ea-application-sdk-config';

import locales from './locales/i18n';
import changeBrandingStyles, {
  BASE_BRANDING, calcBranding, getCurrentAppliedBranding
} from './locales/branding';

import AppBusinessFlows from './flows';

import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/zurich/base/base.scss';
import {
  importCookiesScript
} from './utils/cookies';

import {
  parseGTMConfigByBranding
} from './utils/analytics';

import {
  urlSearchParamsAlt
} from './utils/functions';

interface CustomPromise {
  allSettled: Function;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _CONFIG_: EAVueAppConfiguration;

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wc-auto'];
Vue.use(AppBusinessFlows);

EAHookService.addHookAfterLogin({
  calls: (user: any) => {
    const logger = new EAApplicationLogger();
    logger.info(`main::doLogin::hook::beforeLogin::${user.email}`);
  }
});

const parameters: Partial<EAVueAppConfiguration> = _CONFIG_ ? merge(initialConfig, _CONFIG_) : initialConfig;
const urlParams = urlSearchParamsAlt(window.location);

parameters.appVersion = vApplication.version;

const hooks: EABootstrapHooks = {};

const defaultBranding =
  (urlParams && urlParams.get && urlParams.get('apikey')) || parameters.defaultBranding || 'zurich';
const supportedLocales = parameters.supportedLocales || ['es'];

parameters.analytics = parseGTMConfigByBranding(_CONFIG_, calcBranding(defaultBranding));

const params: EAVueAppParameters = {
  configuration: parameters,
  bootstrapHooks: hooks,
  messages: locales(defaultBranding, supportedLocales)
};

EAApplicationSDKConfig.configure(parameters.integration);

// eslint-disable-next-line
const app = new EAVueApp(params);
const logger = new EAApplicationLogger();
logger.info('main::Application started');

changeBrandingStyles(defaultBranding);

importCookiesScript();
if (getCurrentAppliedBranding() !== BASE_BRANDING) {
  const securityParams = parameters.security as any;
  const newApiKey = securityParams[`${getCurrentAppliedBranding()}ApiKey`];
  EAContextManager.getInstance().getStore()
    .commit('security/setApiKey', newApiKey);
}

/*
 * Promise allsettled polyfill necessary for the chatbot,
 * allSettled method is not available due to Zone implementation
 * so we add it as a polyfill
 */
const CustomPromiseCons = Promise as CustomPromise;
CustomPromiseCons.allSettled = function(promises: Promise<any>[]) {
  const mappedPromises = promises.map(prom => prom
    .then(value => {
      return {
        status: 'fulfilled',
        value
      };
    })
    .catch(reason => {
      return {
        status: 'rejected',
        reason
      };
    }));
  return Promise.all(mappedPromises);
};
