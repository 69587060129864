import {
  steps, StepTransition
} from './stepsConfig';


const zurichProdNavigationTransitions: StepTransition<typeof steps>[] = [
  //App policies
  {
    name: 'AppPolicies',
    from: '*',
    to: 'AppPolicies'
  },
  //Welcome Step
  {
    name: 'Welcome',
    from: 'init',
    to: 'ProductSelection'
  },
  //ProductSelection Step
  {
    name: 'ProductSelection',
    from: 'Welcome',
    to: 'BusinessActivity'
  },
  //BusinessActivity Step
  {
    name: 'BusinessActivity',
    from: 'ProductSelection',
    to: 'PostalAddress',
  },

  /*
   * ////////////////////////////
   * Components
   * ////////////////////////////
   */
  {
    name: 'CallmebackComponent',
    from: '*',
    to: 'TelephonePaymentZurichCalls'
  },
  //Telephone Payment Zurich calls Step
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'TelephonePaymentConfirmation',
    option: 1
  },
  {
    name: 'TelephonePaymentZurichCalls',
    from: 'TelephonePaymentChoose',
    to: 'previous',
    option: 2
  },
  //Payment Processing
  {
    name: 'PaymentProcessing',
    from: 'Welcome',
    to: 'FinishedOnlinePayment'
  },
];

export default zurichProdNavigationTransitions;
